import React, {useEffect} from 'react';
import amplitude from './amplitude'
import './App.css';
import BackgroundMobile from './components/Background mobile'
import Overlay from './components/Overlay'
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      amplitude('page.load')
    }
  },[])
  return (
    <ParallaxProvider>
    <div className="App">
     {/* <Title/>
     <Background/> */}
     <Overlay/>
     <BackgroundMobile/>
    </div>
    </ParallaxProvider>
  );
}

export default App;
