import React from "react";
import { motion } from "framer-motion";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";

import { ReactComponent as DownloadIcon } from "../images/file-download.svg";
import { ReactComponent as ArrowIcon } from "../images/arrow_forward.svg";
import { Button } from "@material-ui/core";

import studentImg from "../images/kids-photo.jpg";
import createImg from "../images/create image.png";
import learnImg from "../images/learn image.png";
import shareImg from "../images/share image.png";

import taiarahia from "../images/tai.png";
import johnson from "../images/johnson.png";
import gerard from "../images/gerard.png";
import harko from "../images/harko.png";

import minEdu from "../images/min edu.png";
import tpk from "../images/tpk.png";
import pataka from "../images/pataka.png";
import tePapa from "../images/te papa.png";
import amplitude from "../amplitude";

import ctBook from "../images/document-images/ct-book.png";
import designBook from "../images/document-images/design-book.png";
import designDoc from "../images/document-images/design-doc.png";
import teachersGuide from "../images/document-images/teachers-guide.png";
import alignmentGuide from "../images/document-images/alignment.png";
import poster from "../images/document-images/poster.png";
import trophy from "../images/document-images/trophy.png";

const useStyles = makeStyles({
  title: {
    fontFamily: "Bungee, cursive",
    color: "#FFFFFF",
    textShadow: "0px 1.5px rgba(9, 20, 69, 0.5)"
  },
  subtitle: {
    fontFamily: "Museo700-Regular",
    color: "#fdf235",
    width: "100%",
    fontSize: "1.3em",
    textShadow: "0px 1px rgba(9, 20, 69, 0.5)"
  },
  subtitleLg: {
    fontFamily: "Museo700-Regular",
    color: "#fdf235",
    width: "80%",
    fontSize: 22,
    textShadow: "0px 1px rgba(9, 20, 69, 0.5)"
  },
  emailText: {
    fontFamily: "Museo500-Regular",
    cursor: 'pointer',
    color: "#fdf235",
    width: "80%",
    fontSize: 13.5,
    textShadow: "0px 1px rgba(9, 20, 69, 0.5)",
    '&:hover': {
        textDecoration: 'underline'
    }
  },
  textBody: {
    fontFamily: "Museo700-Regular",
    marginTop: 10,
    color: "white",
    textShadow: "0px 1px rgba(9, 20, 69, 0.5)",
    fontSize: 17
  },
  textBody2: {
    fontFamily: "Museo500-Regular",
    marginTop: 10,
    color: "white",
    textShadow: "0px 1px rgba(9, 20, 69, 0.5)",
    fontSize: "1rem"
  },
  textBodySm: {
    fontFamily: "Museo500-Regular",
    // marginTop: 10,
    color: "white",
    textShadow: "0px 1px rgba(9, 20, 69, 0.5)",
    fontSize: "0.9rem"
  },
  overlay: {
    position: "absolute",
    marginLeft: "50%",
    // width: '50%',
    // overflow: 'inherit',
    zIndex: 100
  },
  element: {
    position: "relative",
    overflow: "hidden",
    minWidth: 680,
    maxWidth: 750,
    width: "100%",
    left: "-50%"
  },
  creamBox: {
    backgroundColor: "#fef8d2",
    // height: 69,
    // width: '100%',
    borderRadius: 8,
    // cursor: "pointer",
    // padding: 10,

    boxShadow: "0px 1.5px rgba(9, 20, 69, 0.4)"
  },
  downloadIcon: {
    marginTop: 5,
    fill: "#db4636"
  },
  redText: {
    color: "#db4636",
    fontFamily: "Museo700-Regular",
    fontSize: "0.9rem",
    marginTop: 6
  },
  redTextSmall: {
    color: "#db4636bf",
    fontFamily: "Museo500-Regular",
    fontSize: "0.8rem"
  },
  redButton: {
    fontFamily: "Museo700-Regular",
    boxShadow: "0 1px 1px 0 rgba(0,11,64,0.25), 0 2px 6px 0 rgba(0,11,64,0.25)",
    transition: "box-shadow 0.1s ease-in",
    marginTop: 10,
    marginBottom: 10,
    textTransform: "none",
    fontSize: 15,
    borderRadius: 6,
    backgroundColor: "#db4636",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#db4636",
      boxShadow:
        "0 10px 20px 0 rgba(35,50,127,0.4), 0 2px 8px 0 rgba(35,50,127,0.4)"
    }
  },
  creamButton: {
    fontFamily: "Museo700-Regular",
    textDecoration: "underline",
    boxShadow: "0 1px 1px 0 rgba(0,11,64,0.25), 0 2px 6px 0 rgba(0,11,64,0.25)",
    textTransform: "none",
    fontSize: 15,
    borderRadius: 6,
    backgroundColor: "#fef8d2",
    color: "#db4636",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "#fef8d2"
    }
  }
});

function Background() {
  const classes = useStyles();
  const openAndReport = (url, title, report = "download.click") => {
    if (process.env.NODE_ENV === "production") {
      amplitude(report, { title });
    }
    var win = window.open(url, "_blank");
    win.focus();
  };
  return (
    <div>
      <div className={classes.overlay}>
        <div style={{ marginTop: 50 }} className={classes.element}>
          <Typography
            style={{ marginBottom: 5 }}
            className={classes.title}
            variant="h2"
          >
            TE HIKO TĀKARO
          </Typography>
          <Typography style={{ minHeight: 93 }} className={classes.subtitle}>
            A one stop Digital Technologies solution for teachers that weaves
            together coding, culture and creativity.
          </Typography>
        </div>
        <div className={classes.element} style={{ marginTop: 65, overflow: 'initial' }} >
          <Typography className={classes.title} variant="h4">
            DOWNLOAD RESOURCES
          </Typography>
          <Typography className={classes.textBody}>
            These resources weave together Digital Technologies solutions
            covering both Computational Thinking (CT) and Designing and
            Developing for Digital Outcomes (DDDO)
          </Typography>
          <div style={{ paddingRight: 10 }}>
            <Button
              className={classes.redButton}
              style={{ textDecoration: "none", width: "66.666667%" }}
            >
              Code your own 2D platformer
            </Button>
          </div>
          <Grid container>
            <Grid item xs={8}>
              <Typography className={classes.textBody}>
                Student Resources for years 7-10:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.textBody}>
                Teacher Resources:
              </Typography>
            </Grid>
            <Grid
              item
              style={{ paddingRight: 20, marginTop: 10 }}
              xs={8}
              container
              direction="column"
            >
              <Grid item>
                    <div style={{
                        position: 'absolute',
                        borderRadius: '50%',
                        left:-15,
                        marginTop: 10,
                        width: 35,
                        height: 35,
                        boxShadow: "0 1px 1px 0 rgba(0,11,64,0.25), 0 2px 6px 0 rgba(0,11,64,0.25)",
                        backgroundColor: '#db4636',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography style={{
                                fontFamily: "Bungee, cursive",
                                fontSize: 22,
                                color: '#FFF',
                                display: 'inline-block',
                                margin: '0 auto',
                                textShadow: '0px 1.5px rgba(9, 20, 69, 0.5)'
                        }}>1</Typography>
                        </div>
                <div className={classes.creamBox}>
                  <Grid container>
                    {/* <Grid item xs={2}>' */}
                    {/* <DownloadIcon width=50px' height='50px' className={classes.downloadIcon}/> */}
                    <Grid item xs={2}>
                      <img
                        style={{
                          marginLeft: 20,
                          marginBottom: 10,
                          marginTop: 10
                        }}
                        src={ctBook}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        style={{
                          marginTop: 10,
                          marginLeft: 10,
                          paddingRight: 10
                        }}
                        className={classes.redTextSmall}
                      >
                        Students work through the CT Workbook to learn coding
                        concepts & create a cookie-cutter platform game
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                      <div
                        onClick={() =>
                          openAndReport(
                            "https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/teHikoT%C4%81karo_gBook_print.pdf",
                            "Computational Thinking"
                          )
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 10,
                          marginBottom: 10
                        }}
                      >
                        <DownloadIcon
                          style={{ marginRight: 5 }}
                          className={classes.downloadIcon}
                        />
                        <Typography
                          style={{
                            textDecoration: "underline",
                            lineHeight: 1.2
                          }}
                          className={classes.redText}
                        >
                          Download CT Workbook (47mb)
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/** Put it here */}
              <Grid item>
              <div style={{
                        position: 'absolute',
                        boxShadow: "0 1px 1px 0 rgba(0,11,64,0.25), 0 2px 6px 0 rgba(0,11,64,0.25)",
                        borderRadius: '50%',
                        left:-15,
                        marginTop: 20,
                        width: 35,
                        height: 35,
                        backgroundColor: '#db4636',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography style={{
                                fontFamily: "Bungee, cursive",
                                fontSize: 22,
                                color: '#FFF',
                                display: 'inline-block',
                                margin: '0 auto',
                                textShadow: '0px 1.5px rgba(9, 20, 69, 0.5)'
                        }}>2</Typography>
                        </div>
                <div style={{ marginTop: 10 }} className={classes.creamBox}>
                  <Grid container>
                    <Grid item xs={2}>
                      <img
                        style={{
                          marginLeft: 20,
                          marginBottom: 10,
                          marginTop: 10
                        }}
                        src={designBook}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        style={{
                          marginTop: 10,
                          marginLeft: 10,
                          paddingRight: 10
                        }}
                        className={classes.redTextSmall}
                      >
                        Students read the Design booklet and complete its
                        activities
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                      <div
                        onClick={() =>
                          openAndReport(
                            "https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/So%20you%20want%20to%20be%20a%20game%20designer_.pdf",
                            "Designing and Developing Digital Outcomes"
                          )
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 10
                        }}
                      >
                        <DownloadIcon
                          style={{ marginRight: 5 }}
                          className={classes.downloadIcon}
                        />
                        <Typography
                          style={{
                            textDecoration: "underline",
                            lineHeight: 1.2
                          }}
                          className={classes.redText}
                        >
                          Download Design Booklet (6.2mb)
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <img
                        style={{
                          marginLeft: 20,
                          marginBottom: 10,
                          marginTop: 10
                        }}
                        src={designDoc}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        style={{
                          // marginTop: 10,
                          marginLeft: 10,
                          paddingRight: 10
                        }}
                        className={classes.redTextSmall}
                      >
                        Students work through the Student workbook to create
                        their own game design document
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                      <div
                        onClick={() =>
                          openAndReport(
                            "https://docs.google.com/presentation/d/1rp9LxAhPRKJQfezg8nhZvaukGEIBbUcxne9r8thqeLo/edit#slide=id.",
                            "Student Workbook",
                            "copy-slides.click"
                          )
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 15,
                          marginLeft: 10
                        }}
                      >
                        <DownloadIcon
                          style={{ marginRight: 5 }}
                          className={classes.downloadIcon}
                        />
                        <Typography
                          style={{
                            textDecoration: "underline",
                            lineHeight: 1.2
                          }}
                          className={classes.redText}
                        >
                          Open Google Slides and make a copy
                        </Typography>
                      </div>
                    </Grid>
                    {/* <Grid item xs={4}>
                      <div className={classes.creamBox}></div>
                  </Grid> */}
                  </Grid>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.creamBox}>
                <div style={{
                        position: 'absolute',
                        borderRadius: '50%',
                        left:-15,
                        boxShadow: "0 1px 1px 0 rgba(0,11,64,0.25), 0 2px 6px 0 rgba(0,11,64,0.25)",
                        marginTop: 13,
                        width: 35,
                        height: 35,
                        backgroundColor: '#db4636',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography style={{
                                fontFamily: "Bungee, cursive",
                                fontSize: 22,
                                color: '#FFF',
                                display: 'inline-block',
                                margin: '0 auto',
                                textShadow: '0px 1.5px rgba(9, 20, 69, 0.5)'
                        }}>3</Typography>
                        </div>
                  <Grid style={{ marginTop: 10 }} container>
                    {/* <Grid item xs={2}>' */}
                    {/* <DownloadIcon width=50px' height='50px' className={classes.downloadIcon}/> */}
                    <Grid item xs={2}>
                      <img
                        style={{
                          marginLeft: 25,
                          marginBottom: 10,
                          marginTop: 10
                        }}
                        src={trophy}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        style={{
                          marginTop: 10,
                          marginLeft: 10,
                          paddingRight: 10
                        }}
                        className={classes.redTextSmall}
                      >
                        Using their new DDDO and CT skills, students create a
                        final digital outcome
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item container xs={4} direction="column">
              <Grid item>
                <div className={classes.creamBox}>
                  <Grid style={{ marginTop: 10 }} container>
                    {/* <Grid item xs={2}>' */}
                    {/* <DownloadIcon width=50px' height='50px' className={classes.downloadIcon}/> */}
                    <Grid item xs={3}>
                      <img
                        style={{
                          marginLeft: 10,
                          // marginBottom: 10,
                          marginTop: 5
                        }}
                        src={teachersGuide}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        onClick={() =>
                          openAndReport(
                            "https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/So%20you%20want%20to%20teach%20digital%20technologies_.pdf",
                            "Teachers Guide"
                          )
                        }
                        style={{
                          cursor: "pointer",
                          marginTop: 20,
                          marginLeft: 15,
                          paddingRight: 10,
                          textDecoration: "underline",
                          lineHeight: 1.2
                        }}
                        className={classes.redText}
                      >
                        Download Teacher's Guide (6.4mb)
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.creamBox}>
                  <Grid style={{ marginTop: 10 }} container>
                    {/* <Grid item xs={2}>' */}
                    {/* <DownloadIcon width=50px' height='50px' className={classes.downloadIcon}/> */}
                    <Grid item xs={3}>
                      <img
                        style={{
                          marginLeft: 10,
                          // marginBottom: 10,
                          marginTop: 5
                        }}
                        src={alignmentGuide}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        onClick={() =>
                          openAndReport(
                            "https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/DTHM%20Curriculum%20Alignment%20Guide.pdf",
                            "Curriculum Alignment Guide"
                          )
                        }
                        style={{
                          cursor: "pointer",
                          marginTop: 20,
                          marginLeft: 15,
                          paddingRight: 10,
                          textDecoration: "underline",
                          lineHeight: 1.2
                        }}
                        className={classes.redText}
                      >
                        Read Curriculum Alignment Guide
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.creamBox}>
                  <Grid
                    style={{ marginTop: 10, alignItems: "center" }}
                    container
                  >
                    {/* <Grid item xs={2}>' */}
                    {/* <DownloadIcon width=50px' height='50px' className={classes.downloadIcon}/> */}
                    <Grid item xs={3}>
                      <img
                        style={{
                          marginLeft: 10,
                          marginBottom: 10,
                          marginTop: 12
                        }}
                        src={poster}
                      />
                    </Grid>
                    <Grid style={{ paddingLeft: 15 }} item xs={9}>
                      {/* <Typography className={classes.redText}>1. Computational Thinking</Typography> */}

                      <Typography
                        onClick={() =>
                          openAndReport(
                            "https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/What%27s%20in%20a%20game%20poster.pdf",
                            "What's in a Game Poster"
                          )
                        }
                        style={{
                          // marginTop: 10,
                          // marginLeft: 15,
                          cursor: "pointer",
                          marginTop: 0,
                          paddingRight: 10,
                          textDecoration: "underline",
                          lineHeight: 1.2
                        }}
                        className={classes.redText}
                      >
                        Download "What's in a game?" poster (639k)
                      </Typography>
                      {/* <Typography className={classes.redTextSmall}>Download PDF booklet (47.0mb)</Typography> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            {/* <div onClick={() => openAndReport('https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/So%20you%20want%20to%20be%20a%20game%20designer_.pdf', 'Designing and Developing Digital Outcomes')} className={classes.creamBox} item xs={5}>
                        <Grid style={{overflow: 'hidden'}} container>
                            <Grid item xs={2}>
                        <DownloadIcon width='50px' height='50px' className={classes.downloadIcon}/>
                            </Grid>
                            <Grid item xs={10}>

                        <Typography className={classes.redText}>2. Designing and Developing Digital Outcomes</Typography>
                        <Typography className={classes.redTextSmall}>Download PDF booklet (6.2mb)</Typography>
                            </Grid>
                        </Grid>
                        </div>
                    <div onClick={() => openAndReport('https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/So%20you%20want%20to%20teach%20digital%20technologies_.pdf', 'Teachers Guide')} style={{marginRight: '1%'}} className={classes.creamBox}>
                        <Grid style={{overflow: 'hidden'}} container>
                            <Grid item xs={2}>
                        <DownloadIcon width='50px' height='50px' className={classes.downloadIcon}/>
                            </Grid>
                            <Grid item xs={9}>

                        <Typography className={classes.redText}>3. Teachers Guide</Typography>
                        <Typography className={classes.redTextSmall}>Download PDF booklet (6.4mb)</Typography>
                            </Grid>
                        </Grid>
                        </div>
                    <div onClick={() => openAndReport('https://docs.google.com/presentation/d/1rp9LxAhPRKJQfezg8nhZvaukGEIBbUcxne9r8thqeLo/edit#slide=id.', 'Student Workbook', 'copy-slides.click')} className={classes.creamBox}>
                        <Grid style={{overflow: 'hidden'}} container>
                            <Grid item xs={2}>
                        <DownloadIcon width='50px' height='50px' className={classes.downloadIcon}/>
                            </Grid>
                            <Grid item xs={9}>
                        <Typography className={classes.redText}>4. Student Workbook</Typography>
                        <Typography className={classes.redTextSmall}>Open Google slides and make a copy</Typography>
                            </Grid>
                        </Grid>
                        </div>  */}
          </Grid>
          {/* <>
                            <Button onClick={() => openAndReport('https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/What%27s%20in%20a%20game%20poster.pdf', "What's in a Game Poster")} className={classes.creamButton} style={{marginTop: 10, width: '99.65%'}} variant='contained'>
                            <ArrowIcon style={{fill: '#db4636'}}/>Download 'What's in a Game' poster
                            </Button>
                        <Button onClick={() => openAndReport('https://gamefroot.s3.us-east-1.amazonaws.com/takaro.gamefroot.com/DTHM%20Curriculum%20Alignment%20Guide.pdf', 'Curriculum Alignment Guide')} className={classes.redButton} style={{marginTop: 10, width: '99.65%'}} variant='contained'>
                            <ArrowIcon style={{fill: 'white'}}/>Read curriculum alignment guide.
                            </Button>
                         </>    */}
          {/* <Typography className={classes.subtitle} variant='h6'>A one stop Digital Technologies solution that weaves together coding, culture and creativity</Typography> */}
        </div>

        <div
          style={{ marginTop: 100, height: 327.672 }}
          className={classes.element}
        >
          <Typography className={classes.title} variant="h4">
            HOW IT WORKS
          </Typography>
          <Typography
            style={{ marginBottom: 30, width: "100%" }}
            className={classes.textBody}
          >
            Te Hiko Tākaro is a kiwi made Games Based Learning solution
            co-created by game developers from Pōneke, and expert teachers.
          </Typography>
          <Grid container>
            <Grid item xs={5}>
              <img
                alt="Students"
                style={{ maxWidth: 330, width: "85%" }}
                src={studentImg}
              />
            </Grid>
            <Grid item xs={7}>
              <Typography
                style={{ marginTop: -5, width: "100%" }}
                className={classes.textBody2}
              >
                To get started work you way through the booklets in the order
                they are listed. By using these resources you will learn new
                coding concepts, you will design your own unique games and start
                to think like a game designer.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 100 }} className={classes.element}>
          <Typography className={classes.title} variant="h4">
            LEARNING METHODOLOGIES
          </Typography>
          <Grid style={{ marginTop: 30, height: 385 }} container>
            <Grid item xs={4}>
              <img
                alt="Learn"
                style={{ height: 120, marginLeft: 30 }}
                src={learnImg}
              />
              <Typography
                style={{ marginTop: 10 }}
                className={classes.subtitleLg}
                variant="h4"
              >
                Learn
              </Typography>
              <Typography
                style={{ marginTop: 10, marginBottom: 10, width: "95%" }}
                className={classes.textBodySm}
              >
                You and your students:
              </Typography>
              <ul style={{ paddingLeft: 15, marginTop: 0, width: "90%" }}>
                <li className={classes.textBodySm}>
                  <Typography className={classes.textBodySm}>
                    CT Booklet - learn programming concepts.
                  </Typography>
                </li>
                <li className={classes.textBodySm}>
                  <Typography className={classes.textBodySm}>
                    DDDO Booklet - design your game.
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={4}>
              <img alt="Create" style={{ height: 120 }} src={createImg} />
              <Typography
                style={{ marginTop: 10 }}
                className={classes.subtitleLg}
                variant="h4"
              >
                Create
              </Typography>
              <Typography
                style={{ marginTop: 10, marginBottom: 10, width: "90%" }}
                className={classes.textBodySm}
              >
                Combinding your knowledge from DDDO with your CT skills to
                develop a unique piece of digital content.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <img alt="Share" style={{ height: 120 }} src={shareImg} />
              <Typography
                style={{ marginTop: 10 }}
                className={classes.subtitleLg}
                variant="h4"
              >
                Share
              </Typography>
              <Typography
                style={{ marginTop: 10, marginBottom: 10 }}
                className={classes.textBodySm}
              >
                Share your complete Aotearoa-themed game with students, friends,
                and whānau. Do this online via Gamefroot. arcade.gamefroot.com
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 30, height: 300 }} className={classes.element}>
          <Typography
            style={{ marginBottom: 20 }}
            className={classes.title}
            variant="h4"
          >
            OUR ADVISORS
          </Typography>
          <Grid container>
            <Grid container style={{ marginBottom: 30 }} item xs={6}>
              <Grid item xs={3}>
                <img style={{ width: "100%", maxWidth: 100 }} src={taiarahia} />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginLeft: 20 }}>
                  <Typography className={classes.subtitle}>
                    Taiarahia Black
                  </Typography>
                  <Typography className={classes.textBody}>
                    Author + Te Reo Professor
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={3}>
                <img
                  style={{ marginLeft: 60, width: "100%", maxWidth: 100 }}
                  src={johnson}
                />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginLeft: 80 }}>
                  <Typography className={classes.subtitle}>
                    Dr Johnson Witehira
                  </Typography>
                  <Typography className={classes.textBody}>
                    Māori Artist + Designer
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={3}>
                <img style={{ width: "100%", maxWidth: 100 }} src={gerard} />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginLeft: 20 }}>
                  <Typography className={classes.subtitle}>
                    Gerard MacManus
                  </Typography>
                  <Typography className={classes.textBody}>
                    Digital Technologies Expert
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={3}>
                <img
                  style={{ marginLeft: 60, width: "100%", maxWidth: 100 }}
                  src={harko}
                />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginLeft: 80 }}>
                  <Typography className={classes.subtitle}>
                    Harko Brown
                  </Typography>
                  <Typography className={classes.textBody}>
                    Ngā Tākaro Teacher
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 140 }} className={classes.element}>
          <div
            style={{ position: "absolute", width: "100%", height: 200 }}
          ></div>
          <Typography
            style={{ marginBottom: 40 }}
            className={classes.title}
            variant="h4"
          >
            OUR PARTNERS
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <img
                src={minEdu}
                style={{ width: "60%", marginBottom: 20, maxWidth: 260 }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={tpk}
                style={{ marginLeft: 60, width: "60%", maxWidth: 260 }}
              />
            </Grid>
            <Grid item xs={6}>
              <img src={pataka} style={{ width: "60%", maxWidth: 260 }} />
            </Grid>
            <Grid item xs={6}>
              <img
                src={tePapa}
                style={{ marginLeft: 60, width: "60%", maxWidth: 260 }}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 180 }} className={classes.element}>
          <Grid container>
            <Grid item xs={6}>
              <Typography className={classes.title} variant="h4">
                Contact Us
              </Typography>
              <Typography
                style={{ marginTop: 10, width: "100%" }}
                className={classes.textBodySm}
              >
                For more information, email{" "}
                <a
                  className={classes.emailText}
                  href="mailto: dan@gamefroot.com"
                >
                  dan@gamefroot.com
                </a>
              </Typography>
            </Grid>
            <Grid style={{ paddingLeft: 60 }} item xs={6}>
              <Typography className={classes.title} variant="h4">
                Hot Links
              </Typography>
              <Typography
                style={{ marginTop: 10 }}
                className={classes.textBodySm}
              >
                >{" "}
                <a
                  className={classes.emailText}
                  onClick={() =>
                    openAndReport(
                      "https://make.gamefroot.com",
                      null,
                      "hot-links.go-to-gamefroot"
                    )
                  }
                >
                  Game Editor
                </a>
              </Typography>
              <Typography
                style={{ marginTop: 5 }}
                className={classes.textBodySm}
              >
                >{" "}
                <a
                  className={classes.emailText}
                  target="_blank"
                  onClick={() =>
                    openAndReport(
                      "https://www.stuff.co.nz/marlborough-express/113257377/marlborough-students-use-gaming-to-connect-dual-heritage-and-a-shared-future",
                      "Go to Gamefroot",
                      "hot-links.click"
                    )
                  }
                >
                  Connecting code and culture
                </a>
              </Typography>
              <Typography
                style={{ marginTop: 5 }}
                className={classes.textBodySm}
              >
                >{" "}
                <a
                  className={classes.emailText}
                  target="_blank"
                  onClick={() =>
                    openAndReport(
                      "https://www.stuff.co.nz/entertainment/games/76363941/young-programmers-create-games-in-a-day",
                      "Code a game a day",
                      "hot-links.click"
                    )
                  }
                >
                  Code a game a day
                </a>
              </Typography>
              <Typography
                style={{ marginTop: 5 }}
                className={classes.textBodySm}
              >
                >{" "}
                <a
                  className={classes.emailText}
                  target="_blank"
                  onClick={() =>
                    openAndReport(
                      "https://vimeo.com/215943833",
                      "Vimeo",
                      "hotlinks.click"
                    )
                  }
                >
                  Gamefroot on vimeo
                </a>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Background;
